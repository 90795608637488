<template>
  <ion-page>
    <ion-content>
      <ion-title align="center">{{ $t('products.title') }}</ion-title>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-xl="8" size-lg="10" size-md="12" class="ion-no-margin ion-no-padding">
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="6" size-md="12" class="text-block" style="background: #e3e2ff">
                <div>
                  <p>{{ $t('products.descriptionOne') }}</p>
                  <p><b>{{ $t('products.desciptionHeader') }}</b>{{ $t('products.descriptionTwo') }}
                  </p>
                </div>
              </ion-col>
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/muesli.jpg'"></LgtronImage>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-title id="fruits" align="center">{{ $t('products.fruit.title') }}</ion-title>

      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-xl="8" size-lg="10" size-md="12" class="ion-no-margin ion-no-padding">
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="6" size-md="12" class="text-block" style="background: #f3d8e4">
                <div>
                  <p>{{ $t('products.fruit.description') }}
                  </p>
                </div>
              </ion-col>
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/strawberries.jpg'"></LgtronImage>
                <div class="item-text">{{ $t('products.fruit.strawberryDescription') }}</div>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/banana.jpg'"></LgtronImage>
                <div class="item-text">{{ $t('products.fruit.bananaDescription') }}</div>
              </ion-col>
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/mango.jpg'"></LgtronImage>
                <div class="item-text">{{ $t('products.fruit.mangoDescription') }}</div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-title id="vegetables" align="center">{{ $t('products.vegetable.title') }}</ion-title>

      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-xl="8" size-lg="10" size-md="12" class="ion-no-margin ion-no-padding">
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="6" size-md="12" class="text-block" style="background: #ffca22">
                <div>
                  <p>{{ $t('products.vegetable.description') }}
                  </p>
                </div>
              </ion-col>
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/okra.jpg'"></LgtronImage>
                <div class="item-text">{{ $t('products.vegetable.okraDescription') }}</div>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/spinach.jpg'"></LgtronImage>
                <div class="item-text">{{ $t('products.vegetable.spinachDescription') }}</div>
              </ion-col>
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/corn.jpg'"></LgtronImage>
                <div class="item-text">{{ $t('products.vegetable.cornDescription') }}</div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-title id="spices" align="center">{{ $t('products.spices.title') }}</ion-title>

      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-xl="8" size-lg="10" size-md="12" class="ion-no-margin ion-no-padding">
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="6" size-md="12" class="text-block" style="background: #c0dcb2">
                <div>
                  <p>{{ $t('products.spices.description') }}
                  </p>
                </div>
              </ion-col>
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/shiitake.jpg'"></LgtronImage>
                <div class="item-text">{{ $t('products.spices.shiitakeDescription') }}</div>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/fungus.jpg'"></LgtronImage>
                <div class="item-text">{{ $t('products.spices.puppetDescription') }}</div>
              </ion-col>
              <ion-col size-lg="6" size-md="12" class="ion-no-padding ion-no-margin">
                <LgtronImage :src="'/assets/images/rosemary.jpg'"></LgtronImage>
                <div class="item-text">{{ $t('products.spices.rosemaryDescription') }}</div>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>

      <LgtronFooter></LgtronFooter>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol} from '@ionic/vue';
import {LgtronImage, LgtronFooter} from "@/components";

export default {
  name: 'Products',
  components: {IonTitle, IonContent, IonPage, IonGrid, IonRow, IonCol, LgtronImage, LgtronFooter},
  created: function () {
    console.log("products are shown");
  }
}
</script>

<style scoped lang="scss">

$item-text-margin: 1rem;

.lgtron-image {
  display: block;
  height: 100%;
  min-height: 20rem;
  object-fit: cover;
  width: 100%;
}

.item-text {
  background: rgba(0, 0, 0, .5);
  color: #fff;
  font-size: 1.2rem;
  position: absolute;
  bottom: 0;
  margin: $item-text-margin;
  padding: 1rem;
  text-align: center;
  width: calc(100% - #{$item-text-margin} * 2);
}

@media screen and (max-width: 50rem) {
  ion-col {
    min-width: 100%;
  }
}

</style>