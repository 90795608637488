
import {IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol} from '@ionic/vue';
import {LgtronImage, LgtronFooter} from "@/components";

export default {
  name: 'Products',
  components: {IonTitle, IonContent, IonPage, IonGrid, IonRow, IonCol, LgtronImage, LgtronFooter},
  created: function () {
    console.log("products are shown");
  }
}
